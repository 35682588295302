<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="orderPurchaseTemplate" :timeout="4000" top>
      <span>Order Purchase Email Templates Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="orderPurchaseTemplate = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-card>
      <v-card-title>
        <h3>Order Purchase Email Templates</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="orderPurchaseEmail">
              <v-container class="py-2">
                <vue-editor v-model="orderPurchaseEmailTemplate"></vue-editor>
                <p style="color: red" v-if="showOrderPurchaseError">
                  Order Purchase Email Templates is required
                </p>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mt-3"
                      @click="submitOrderPurchaseEmail"
                      :loading="loading"
                      >Update</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "OrderPurchaseEmail",
  components: { VueEditor },
  data() {
    return {
      loading: false,
      orderPurchaseEmailTemplate: "",
      showOrderPurchaseError: false,
      orderPurchaseID: "",
      orderPurchaseTemplate: false,
    };
  },
  methods: {
    orderPurchaseEmail() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "setting/edit/order_purchase_email_template"
        )
        .then((response) => {
          if (response.status == 200) {
            this.orderPurchaseID = response.data.setting._id;
            this.orderPurchaseEmailTemplate = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitOrderPurchaseEmail() {
      if (this.orderPurchaseEmailTemplate != "") {
        this.showOrderPurchaseError = false;
        this.loading = true;
        let data = {
          id: this.orderPurchaseID,
          type: "order_purchase_email_template",
          value: this.orderPurchaseEmailTemplate,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.orderPurchaseTemplate = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showOrderPurchaseError = true;
      }
    },
  },
  mounted() {
    this.orderPurchaseEmail();
  },
};
</script>